<template>
    <div><textarea-input /></div>
</template>

<script>
import BaseComponent from '@/component/Editor/BaseComponent';
export default {
    name    : 'editor-text-area',
    extends : BaseComponent

};
</script>
