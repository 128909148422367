import { render, staticRenderFns } from "./UserAcquisitionReports.vue?vue&type=template&id=0b245edf&scoped=true&"
import script from "./UserAcquisitionReports.vue?vue&type=script&lang=js&"
export * from "./UserAcquisitionReports.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b245edf",
  null
  
)

export default component.exports