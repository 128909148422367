export default {
    '4c3cc5bc-0452-46f4-b085-575e3530ea24' : 'Muhammed K K',
    'a0d5b848-1b80-44c6-99b8-e40621931a8c' : 'q1w2e3r4',
    'be38cf02-aa9d-41af-9bf1-4429d1e406a5' : {
        rate : {
            0 : '1',
            1 : '2',
            2 : '3'
        },
        total : {
            0 : '10',
            1 : '20',
            2 : '30'
        }
    },
    '9a059cd9-0903-4f5b-b63a-2565d21d4bf5' : {
        unit : {
            0 : '1',
            1 : '2',
            2 : '3'
        },
        rate : {
            0 : '10',
            1 : '20',
            2 : '30'
        }
    },
    'a94612de-e118-47ba-a07a-935a5f28df05' : {
        amount : {
            0 : '10',
            1 : '20',
            2 : '30'
        }
    },
    'c85fa591-0a99-44e5-9ee3-4df1273f6819' : 'asdasdad'

};
