<template>
    <div>
        <div v-if="loading" class="bg-primary p-3 mb-4" style="border-radius: 6px;">
            <loading-animation/>
            <span style=" vertical-align: super"> Loading details....</span>
        </div>
        <div v-if="!loading && permission" class="container card bs-5">

            <div class="row text-right mb-3">
                <div class="col text-left"><h4>{{ details.name }}</h4></div>
                <div class="col">
                    <btn type="button" color="warning" class="mr-2" size="sm" @click="printClicked" text="Print"/>
                    <btn v-if="currentUser.features.create_new_tender" type="button" color="success" size="sm"
                         @click="createTender(details)" text="Create Tender"/>
                    <btn type="button" color="primary" class="ml-2" size="sm"
                         @click="$router.push('/app/my-template/')" text="Back"/>
                </div>
            </div>

            <!--            Template Edit Form           -->

            <div v-if="details">
                <BidView class="" :json="initial_json" :variable-json="variablesJson"
                         :title="details.name + ' # ' + details.id"/>
            </div>
        </div>
        <div v-if="!permission" class="card text-center bg-primary">Page not found!</div>

        <!--            Form Modals           -->

        <modal title="Create Tender" ref="addModal" width="30r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addUrl" @success="formSuccess1" v-slot="{model, loading}"
                    :save-params="{ json, my_template }">
                <validated-input label="Tender Name"
                                 v-model="model.name"
                                 :rules="rules.name"
                                 :disabled="loading"/>
                <validated-input label="Tender Number" name="Tender Number"
                                 v-model="model.tender_number"
                                 :rules="rules.tender_number"
                                 :disabled="loading"/>
                <save-btn icon="" text="Create" color="primary" size="sm" :disabled="loading"/>
            </b-form>
        </modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';
import BidView from '@/component/Bidder/BidView';
// import OneBid from '@/data/Samples/OneBid';

export default {
    name       : 'MyTemplateDetailsPage',
    components : { BidView },
    data () {
        return {
            my_template   : '',
            permission    : true,
            loading       : false,
            id            : this.$route.params.id,
            addUrl        : urls.Consumer.Tender.create,
            initial_json  : null,
            json          : '',
            variablesJson : '',
            details       : [],
            editingItem   : {
                id : this.$route.params.id
            },
            rules : {
                name : {
                    required : true
                },
                tender_number : {
                    required : true
                }
            }
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    beforeMount () {
        this.loadDetails();
    },
    methods : {
        printClicked () {
            const baseData = [{ tabName : 'First Tab', data : [] }];
            const currentJson = JSON.parse(JSON.stringify(this.details.json));
            if (JSON.stringify(baseData) !== JSON.stringify(currentJson)) {
                this.printScreen();
            } else {
                this.$notify('Not enough content to generate pdf.', 'Message', {
                    type : 'info'
                });
            }
        },
        printScreen (item) {
            const that = this;
            that.loading = true;
            axios.form(urls.Consumer.Tender.Print, { template_id : that.id }).then(function (response) {
                const json = response.data;
                if (json.url) {
                    that.$notify('Pdf generated successfully', 'Success', {
                        type : 'success'
                    });
                    window.open(json.url);
                    that.loading = false;
                } else {
                    that.$notify('Pdf generation failed', 'Error', {
                        type : 'danger'
                    });
                    that.loading = false;
                }
                that.loading = false;
            });
            that.loading = false;
        },
        createTender (details) {
            this.json = JSON.stringify(details.json);
            this.$refs.addModal.show();
        },
        onSave (json) {
            // Implement save here.
            this.editingItem.json = json;
            const that = this;
            axios.form(urls.Consumer.MyTemplate.create, that.editingItem).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('My template saved', 'Success',
                        {
                            type : 'success'
                        }
                    );
                    that.formSuccess();
                }
            });
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.Consumer.MyTemplate.details, { id : this.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json.template;
                    that.my_template = json.template.id;
                    that.variablesJson = JSON.stringify(json.template.json);
                    that.initial_json = JSON.stringify(json.template.json);
                    that.loading = false;
                } else if (json.permission === false) {
                    that.permission = json.permission;
                    that.loading = false;
                } else if (json.error === true) {
                    that.permission = json.permission;
                    that.loading = false;
                }
            });
        },
        formSuccess (response) {
            this.details = response.data.details;
        },
        formSuccess1 (response) {
            this.$refs.addModal.close();
        }
    }
}
;
</script>

<style scoped>
.text-small {
    font-weight: 500;
}

.form-control {
    border-radius: 5px;
}
</style>
