var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('check-permission'),(_vm.currentUser.features.standard_template || _vm.currentUser.is_superuser)?_c('vue-table-card',{ref:"table",staticClass:"card bs-5",attrs:{"title":"Standard Templates","fields":_vm.fields,"url":_vm.listUrl,"per-page":10},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.currentUser.is_superuser)?_c('btn',{staticClass:"mr-2",attrs:{"color":"success","icon":"fa fa-file","size":"sm","text":"Import Template"},on:{"click":function($event){return _vm.$refs.importTemplateModal.show()}}}):_vm._e(),(_vm.currentUser.is_superuser)?_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}}):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[(_vm.currentUser.is_superuser)?_c('edit-btn',{attrs:{"size":"xs"},on:{"click":function($event){return _vm.setEdit(rowData)}}}):_vm._e(),(_vm.currentUser.is_superuser)?_c('delete-btn',{attrs:{"size":"xs"},on:{"click":function($event){return _vm.setDelete(rowData)}}}):_vm._e(),(_vm.currentUser.is_superuser)?_c('edit-btn',{staticClass:"primary",attrs:{"icon":"","size":"xs","color":"primary","text":"View"},on:{"click":function($event){return _vm.viewDetails(rowData)}}}):_vm._e(),(!rowData.is_published && _vm.currentUser.is_superuser)?_c('btn',{attrs:{"color":"success","size":"xs","text":"Publish"},on:{"click":function($event){return _vm.publishTemplate(rowData)}}}):_vm._e(),(rowData.is_published && _vm.currentUser.is_superuser)?_c('btn',{attrs:{"color":"warning","size":"xs","text":"Suppress"},on:{"click":function($event){return _vm.publishTemplate(rowData)}}}):_vm._e(),_c('edit-btn',{staticClass:"bgc-primary-600",attrs:{"icon":"","size":"xs","color":"info","text":"Preview"},on:{"click":function($event){return _vm.showBidPreview(rowData)}}})],1)]}},{key:"footer",fn:function(){return [_c('modal',{ref:"importTemplateModal",attrs:{"no-close-on-backdrop":"","title":"Import Template","width":"30r","header-color":"primary"}},[_c('b-form',{attrs:{"save-url":_vm.importUrl},on:{"success":_vm.importSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Standard Template","name":"name","disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('validated-file-input',{attrs:{"label":"File","name":"file","disabled":loading},model:{value:(model.file),callback:function ($$v) {_vm.$set(model, "file", $$v)},expression:"model.file"}}),_c('save-btn',{attrs:{"text":"Import","icon":"","color":"primary","size":"sm","disabled":loading}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Importing...")])],1):_vm._e()])]}}],null,false,3157250892)})],1),_c('modal',{ref:"addModal",attrs:{"title":"Add Standard Template","width":"30r","header-color":"primary"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{json: _vm.json}},on:{"success":_vm.formSuccess,"failure":_vm.formError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Standard Template","disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('save-btn',{attrs:{"icon":"","text":"Save","color":"primary","size":"sm","disabled":loading}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Saving...")])],1):_vm._e()])]}}],null,false,1545080952)})],1),_c('modal',{ref:"editModal",attrs:{"title":"Edit Standard Template","width":"30r","header-color":"primary"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Name","disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('save-btn',{attrs:{"icon":"","text":"Update","color":"primary","size":"sm","disabled":loading}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Updating...")])],1):_vm._e()])]}}],null,false,1795990996)})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}],null,false,2459264348)},[_c('p',[_vm._v("You are about to delete the Standard Template "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". Are you sure ?")])])]},proxy:true}],null,false,1559054338)}):_vm._e(),((!_vm.currentUser.features.standard_template) && (!_vm.currentUser.is_superuser))?_c('div',{staticClass:"card text-center bg-primary"},[_vm._v(" Sorry, You don't have permission to view this page. Kindly contact your Owner. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }