<template>
    <div>
        <div v-if="loading" class="bg-primary p-3 mb-4" style="border-radius: 6px;">
            <loading-animation/>
            <span style=" vertical-align: super"> Loading details....</span>
        </div>
        <div v-if="!loading" class="card">
            <div class="p-4">
                <div v-if="tender" class="row mb-3">
                    <div class="col"><b>Project Name</b> : {{ tender.name_of_project }}</div>
                </div>
                <div v-if="tender" class="row mb-3">
                    <div class="col"><b>Date of Report</b> : {{ tender.date_of_report }}</div>
                </div>
                <div class="row">
                    <div class="col" style="width: 50%">
                        <perfect-scrollbar>
                            <table id="table-1" class="table table-bordered" ref="table">
                                <thead>
                                <tr>
                                    <td colspan="2">
                                        <span v-if="tender"><b>Client Name</b> : {{ tender.name_of_client }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Item</td>
                                    <td class="font-weight-bold">Description</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(record) in  details1" v-bind:key="record.key">
                                    <td v-for="(i) in  record" v-bind:key="i.key" scope="row"><pre>{{ i }}</pre></td>
                                </tr>
                                </tbody>
                            </table>
                        </perfect-scrollbar>
                    </div>
                    <div class="col" style="width: 50%; margin: 0px !important;">
                        <perfect-scrollbar>
                            <table id="table-2" class="table table-bordered" ref="table">
                                <thead>
                                <tr v-if="detail">
                                    <td colspan="2" class="text-center font-weight-bold heading"
                                        v-for="(record) in  detail[0].contractors" v-bind:key="record.id"><pre>{{ record.name }}</pre>
                                    </td>
                                    <td class="text-center font-weight-bold heading" colspan="2"></td>
                                </tr>
                                <tr v-if="detail">
                                    <td colspan="2" class="text-center heading font-weight-bold" style="width: fit-content"
                                        v-for="(record) in  detail[0].contractors" v-bind:key="record.id">Amount
                                    </td>
                                    <td class="text-center font-weight-bold heading" colspan="2">Average</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(record) in  details" v-bind:key="record.key">
                                    <td colspan="2" v-for="(i) in  record" v-bind:key="i.key">
                                        <div class="text-center"
                                             v-bind:style="selectColour(record, i)"><pre>{{ i }}</pre>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
            </div>
        </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name  : 'BidderAnalysisComparisonAmountPage',
    props : { tender_id : { type : String } },
    data () {
        return {
            id         : this.$route.params.id,
            permission : true,
            loading    : false,
            details    : [],
            details1   : [],
            detail     : null,
            table_head : [],
            tender     : null
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        selectColour (record, i) {
            const sortedArray = [];
            const arrayLength = record.length;
            for (let i = 0; i < arrayLength; i++) {
                if (typeof record[i] === 'number') {
                    sortedArray.push(record[i]);
                }
            }
            if (i === (Math.max(...sortedArray)) && (typeof i === 'number')) {
                const data = { background : '#f8696b', color : 'white' };
                return data;
            } else if (i === (Math.min(...sortedArray)) && (typeof i === 'number')) {
                const data = { background : '#63be7b', color : 'white' };
                return data;
            } else if (i > (Math.min(...sortedArray)) && i < (Math.max(...sortedArray)) && (typeof i === 'number')) {
                const data = { background : '#ffeb84', color : 'white' };
                return data;
            } else {
                const data = { fontWeight : 'bold', fontSize : '13px' };
                return data;
            }
        },
        stripAnalysisDetails () {
            const initialData = this.detail;
            const data = [];
            const data1 = [];
            const tableHead = [];
            const tableBody = [];
            const subDetails = [];
            const arrayLength = initialData.length;
            for (let i = 0; i < arrayLength; i++) {
                var arrayLength1 = initialData[i].contractors.length;
                tableBody.push([initialData[i].index, initialData[i].description]);
                subDetails.push([]);
                for (let j = 0; j < arrayLength1; j++) {
                    subDetails[i].push(
                        parseInt(initialData[i].contractors[j].amount)
                    );
                }
                const average = (initialData[i].averages).toString();
                subDetails[i].push(average);
                data.push(subDetails);
                data1.push(tableBody);
            }
            this.details = data[data.length - 1];
            this.details1 = data1[data1.length - 1];
            this.table_head = tableHead;
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.Consumer.Tender.ComparisonAmountAnalysisView, { tender_id : that.tender_id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    if (json['boq-table']) {
                        that.detail = json['boq-table'];
                    } else if (json['boq-wo-qty-table']) {
                        that.detail = json['boq-wo-qty-table'];
                    } else if (json['sot-table']) {
                        that.detail = json['sot-table'];
                    } else if (json['msp-mnt-table']) {
                        that.detail = json['msp-mnt-table'];
                    }
                    that.tender = json.tender;
                    that.stripAnalysisDetails();
                    that.loading = false;
                } else if (json.permission === false) {
                    that.permission = json.permission;
                    that.loading = false;
                } else if (json.error === true) {
                    that.permission = json.permission;
                    that.loading = false;
                }
            });
        }
    }
}
;
</script>

<style scoped>
.text-small {
    font-weight: 500;
}

.form-control {
    border-radius: 5px;
}

th, .heading {
    font-size: 13px !important;
}

pre {
    font-family: Montserrat, sans-serif;
}
</style>
