<template>
    <div class="card">
        <div class="">
            <div class="p-0">
                <div class="row">
                    <div class="col-3 pl-4 pr-3 br-1">
                        <div class=" row p-0 ">
                            <div class="col-lg-6  col-7">

                                <p class="txt-clr-custom-2 font-weight-bold">Filters</p>

                            </div>
                            <div class="col-lg-6 col-5 fl-x-br cursor-pointer" @click="clearFilter">
                                <p class=" red font-weight-bold">Clear All</p>

                            </div>
                        </div>
                        <validated-input v-model="search" placeholder="Search by Company Name"/>
                        <validated-ajax-vue-select name="Industry" :url="industryOptions" label="Industry"
                                                   v-model="industry" class="c-input-select"/>
                        <validated-ajax-vue-select name="Vendor Category" :url="vendorCategoryOptions"
                                                   label="Vendor Category" class="c-input-select"
                                                   v-model="vendor_category"/>
                        <validated-ajax-vue-select name="Services Provided" :url="servicesOptions"
                                                   class="c-input-select"
                                                   label="Services Provided" v-model="services_provided"/>
                        <validated-ajax-vue-select name="Country" :url="countryOptions"
                                                   @input="loadStateOptions1(country)" label="Country"
                                                   v-model="country" class="c-input-select"/>
                        <validated-vue-select name="State" :options="stateOptions"
                                              @input="loadDistrictOptions1(state)"
                                              label="State" v-model="state" class="c-input-select"/>
                        <validated-vue-select name="District" :options="districtOptions"
                                              @input="checkIfNullValue(district)" label="District"
                                              v-model="district" class="c-input-select"/>
                        <btn @click="loadData" text="Search" color="primary"
                             icon="fa fa-filter" class="btn-block"/>
                    </div>
                    <div v-if="loading" class="col-10 row text-center">
                        <div class="col p-3 mb-4 mt-7" style="border-radius: 6px;">
                            <loading-animation/>
                            <span style=" vertical-align: super"> Loading details....</span>
                        </div>
                    </div>
                    <div v-if="!loading && details.data.length!==0" class="col-9 row of-h">
                        <div class="row ml-3 mb-3 h-100 pt-2 w-100p">
                            <div v-for="(i, index) in details.data" :key="index"
                                 class="col-4  mb-3  cursor-pointer">
                                <div class="m-1 p-3 bs-4 h-100p ">
                                    <div class="fl-x-br mt-3">
                                        <btn color="warning" class="mr-1" size="xs" @click="removeFromMyContacts(i)"
                                             text="Remove"/>
                                        <btn color="primary" size="xs" @click="viewDetails(i)" text="View"/>
                                    </div>
                                    <div class="fl-x-cc mt-5">
                                        <img v-if="i.company_logo" alt="" :src="i.company_logo">
                                        <div v-else class="p-4 b-1"><i class="small">Company Logo</i></div>
                                    </div>
                                    <p class="text-center mt-4 font-weight-bold fs--1 txt-clr-custom">
                                        {{ i.company_name }}<br>
                                        {{ i.company_registration_no }}
                                    </p>
                                    <div class="fl-x-tl mt-3 mb-0">
                                        <img alt=""
                                             src="../../../assets/images/icons/material-symbols_phone-enabled-sharp.png"
                                             class="mr-2 mt-1">
                                        <p class="ic-text mb-0">{{ i.contact_number }}</p>
                                    </div>
                                    <div class="fl-x-tl mt-0">
                                        <img alt="" src="../../../assets/images/icons/material-symbols_mail-rounded.png"
                                             class="mr-2 mt-1">
                                        <p class="ic-text">{{ i.contact_e_mail_id }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="details.data.length !== 0" class="col-9 mt-4 fl-te-c">
                                <div>
                                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                                </div>
                                <div>
                                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                                         icon="fa fa-chevron-left"/>
                                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                                         icon="fa fa-chevron-right"/>
                                    <btn class="m-1" size="sm" disabled v-else type="button"
                                         icon="fa fa-chevron-right"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!loading && details.data.length===0" class="col-9 row text-center">
                        <div class="col p-3 mb-4 mt-7" style="border-radius: 6px;">
                            <span style=" vertical-align: super"> No data available</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal no-close-on-backdrop title="Details Page" ref="showDetailsPage" width="70r" header-color="primary">
            <details-page :company_id="id.toString()"/>
        </modal>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import DetailsPage from './DetailsPage';

export default {
    name       : 'Company',
    components : { DetailsPage },
    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '9',

            id                    : '',
            details               : '',
            loading               : false,
            search                : '',
            vendor_category       : '',
            country               : '',
            state                 : '',
            district              : '',
            services_provided     : '',
            industry              : '',
            vendorCategoryOptions : urls.Admin.Company.vendorCategoryOptions,
            industryOptions       : urls.Admin.Company.industryOptions,
            servicesOptions       : urls.Admin.Company.servicesOptions,
            districtOptions       : [],
            stateOptions          : [],
            countryOptions        : urls.Admin.MasterData.State.countryOptions,

            listUrl : urls.Consumer.MyContact.myContactList
        };
    },
    mounted () {
        this.loadData();
    },
    methods : {
        viewDetails (rowData) {
            this.id = '';
            this.$refs.showDetailsPage.show();
            this.id = rowData.company_id;
        },
        async removeFromMyContacts (rowData) {
            await axios.form(urls.Consumer.MyContact.removeFromMyContacts, { id : rowData.id });
            this.loadData();
        },
        clearFilter () {
            this.search = '';
            this.vendor_category = '';
            this.services_provided = '';
            this.industry = '';
            this.country = '';
            this.state = '';
            this.district = '';
            this.districtOptions = [];
            this.stateOptions = [];
            this.loadData();
        },
        loadData () {
            const that = this;
            that.loading = true;
            axios.get(this.listUrl, {
                params : {
                    page              : that.page,
                    per_page          : that.per_page,
                    search            : that.search,
                    vendor_category   : that.vendor_category,
                    services_provided : that.services_provided,
                    industry          : that.industry,
                    country           : that.country,
                    state             : that.state,
                    district          : that.district
                }
            }).then(function (response) {
                const json = response.data;
                if (json.status === true) {
                    that.details = json;
                    that.loading = false;
                    that.setPagination();
                } else {
                    that.$notify('Could not load the data, Please try again later.', '', {
                        type : 'danger'
                    });
                    that.loading = false;
                }
                that.loading = false;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            this.loadData();

            this.editingItem = null;
        },
        loadStateOptions1 (item) {
            if (this.country === null) {
                this.country = '';
            }
            this.state = '';
            this.district = '';
            this.stateOptions = [];
            this.districtOptions = [];
            const that = this;
            axios.form(urls.Admin.Company.stateOptions, { country : item }).then(function (response) {
                const json = response.data;
                that.stateOptions = json.data;
                that.districtOptions = [];
            });
        },
        loadDistrictOptions1 (item) {
            this.districtOptions = [];
            if (this.state === null) {
                this.state = '';
            }
            this.district = '';
            const that = this;
            axios.form(urls.Admin.Company.districtOption, { state : item }).then(function (response) {
                const json = response.data;
                that.districtOptions = json.data;
            });
        },
        loadStateOptions (item) {
            if (this.$refs.addForm.getModelValue('country') === null) {
                this.$refs.addForm.setModelValue('country', '');
            }
            this.$refs.addForm.setModelValue('state', '');
            this.$refs.addForm.setModelValue('district', '');
            this.stateOptions = [];
            this.districtOptions = [];
            const that = this;
            axios.form(urls.Admin.Company.stateOptions, { country : item }).then(function (response) {
                const json = response.data;
                that.stateOptions = json.data;
                that.districtOptions = [];
            });
        },
        loadDistrictOptions (item) {
            if (this.$refs.addForm.getModelValue('state') === null) {
                this.$refs.addForm.setModelValue('state', '');
            }
            this.districtOptions = [];
            this.$refs.addForm.setModelValue('district', '');
            const that = this;
            axios.form(urls.Admin.Company.districtOption, { state : item }).then(function (response) {
                const json = response.data;
                that.districtOptions = json.data;
            });
        },
        checkIfNullValue (item) {
            if (this.$refs.addForm.getModelValue('district') === null) {
                this.$refs.addForm.setModelValue('district', '');
            }
        }
    }
};
</script>

<style scoped lang="scss">
.bs-custom {
    background-color: #f6fbff;
}

.bs-custom:hover {
    background: #eef2f6;
}

.label-fs {
    font-size: 11px;
    color: #284565;

}

.txt-clr-custom {
    color: #284565;
    font-size: 13px;

}

.txt-clr-custom-1 {
    color: #284565;

}

.txt-clr-custom-2 {
    color: #284565;
    font-size: 13px;

}

.red {
    color: red;
    font-size: 12px;
}

.ic-text {
    font-size: 12px;
    color: #284565;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.item {
    padding: 3rem;
    border: #ccc 1px solid;
    background: #f4f4f4;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
}

.item:first-child {
    grid-column-start: 1;
    grid-column-end: 5;
}

.zsm img {
    height: 53px;
    width: 100px;
}

.c-input-select {
    .form-control {
        position: relative;
        padding: var(--spacer-1) var(--spacer-2) !important;

        input {
            //font-family: 'inter-bold';
            //font-size: 0.8rem;
            padding: var(--spacer-1) var(--spacer-2) !important;
            color: var(--color-white) !important;
        }

        .form-check > .control:focus-visible, .form-control > .control:focus-visible {
            color: white;
        }

        .vs__open-indicator {
            display: none !important;
        }

        .vs__actions {
            display: none !important;
        }

        &:after {
            content: '';
            position: absolute;
            right: var(--spacer-3);
            top: 50%;
            transform: translateY(-50%) !important;
            width: 16px;
            height: 16px;
            background: url("../../../assets/images/icons/material-symbols_arrow-forward-ios-rounded.png");
            transform: rotate(90deg);
        }
    }
}
</style>
