<template>
    <vue-table-card title="Users" :extra-params="{ company }" class="card bs-5" :fields="fields" :url="listUrl"
                    :per-page="10" ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="addRole" text="Add"/>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn size="xs" v-if="currentUser.role==='owner'" @click="setDelete(rowData)"/>

                <btn v-if="rowData.is_suspended === true && !rowData.is_superuser"
                     color="warning" size="xs" text="Suspended"/>

                <btn color="primary" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add User" ref="addModal" header-color="primary" no-close-on-backdrop>
                <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{company}">
                    <div class="row">
                        <div class="col-md-4">
                            <validated-input name="Name" :rules="rules.name"
                                             label="Name" :disabled="loading"
                                             v-model="model.name"></validated-input>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Mobile Number" :rules="rules.staff_phone_number" type="number"
                                             label="Mobile Number" :disabled="loading"
                                             v-model="model.staff_phone_number"></validated-input>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="E-Mail" :rules="rules.email" type="email"
                                             label="E-Mail" :disabled="loading"
                                             v-model="model.email"></validated-input>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-input name="Username" :rules="rules.username"
                                             label="Username" :disabled="loading"
                                             v-model="model.username"></validated-input>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Password" :rules="rules.password1" type="password"
                                             label="Password" :disabled="loading"
                                             v-model="model.password1"></validated-input>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Confirm Password" :rules="rules.password2" type="password"
                                             label="Confirm Password" :disabled="loading"
                                             v-model="model.password2"></validated-input>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-vue-select name="Role" :options="roleOptions" ref="roleOptionReload"
                                                  :rules="rules.role" label="Role"
                                                  v-model="model.role"></validated-vue-select>
                        </div>
                        <div class="col-md-4">
                            <validated-vue-select name="Address Type" :options="addressTypeOptions"
                                                  label="Address Type" :disabled="loading"
                                                  v-model="model.address_type"></validated-vue-select>
                        </div>
                        <div class="col-md-4">
                            <checkbox-input class="mt-4" name="Is Primary Address" label="Is Primary Address"
                                            :disabled="loading"
                                            v-model="model.is_primary_address"></checkbox-input>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label>Address</label>
                            <textarea-input name="Address" v-model="model.address"/>
                        </div>
                    </div>
                    <save-btn text="Save" icon="" color="primary" size="sm" :disabled="loading">
                            <span v-if="loading"><loading-animation/><span
                                style="vertical-align: super"> Saving...</span></span>
                    </save-btn>
                </b-form>
            </modal>
            <modal no-close-on-backdrop title="User Details" ref="userDetails" header-color="primary">
                <user-details-page :detail="detail_id"/>
            </modal>
            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the company <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                    ?</p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '@/data/urls';
import UserDetailsPage from './UserDetails';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';

export default {
    name       : 'Users',
    props      : { detail : { type : String } },
    components : { UserDetailsPage },
    data () {
        return {
            company        : this.detail,
            roleOptions    : [],
            companyOptions : urls.Admin.User.companyOptions,

            listUrl     : urls.Admin.User.list,
            addUrl      : urls.Admin.User.addEdit,
            editRoleUrl : urls.Admin.User.editRole,
            deleteUrl   : urls.Admin.User.delete,

            editingItem        : null,
            deletingItem       : null,
            detail_id          : '',
            addressTypeOptions : [
                { label : 'Office', value : 'Office' },
                { label : 'Home', value : 'Home' },
                { label : 'Other', value : 'Other' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'role_name',
                    sortField : 'role_name',
                    title     : 'Role'
                },
                {
                    name      : 'company',
                    sortField : 'company',
                    title     : 'Company'
                },
                {
                    name      : 'staff_phone_number',
                    sortField : 'staff_phone_number',
                    title     : 'Phone'
                },
                {
                    name       : '__slot:actions',
                    title      : '',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ],
            rules : {
                name : {
                    required : true
                },
                role : {
                    required : true
                },
                staff_phone_number : {
                    required : true
                },
                username : {
                    required : true
                },
                password1 : {
                    required : true
                },
                password2 : {
                    required : true
                },
                email : {
                    required : true
                }
            }
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    mounted () {
        this.loadRoleOption();
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            this.loadRoleOption();
            refs.table.refreshTable();

            this.editingItem = null;
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        viewDetails (rowData) {
            this.$refs.userDetails.show();
            this.detail_id = rowData.id;
        },
        addRole (item) {
            this.$refs.addModal.show();
        },
        loadRoleOption () {
            const that = this;
            axios.form(urls.Admin.User.roleOptions, { company : that.company }).then(function (response) {
                const json = response.data;
                that.roleOptions = json.data;
            });
        },
        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
            this.loadRoleOption();
        }
    }
};
</script>

<style scoped>

</style>
