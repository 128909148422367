var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('check-permission'),_c('vue-table-card',{ref:"table",staticClass:"card bs-5",attrs:{"title":"My Template","url":_vm.listUrl,"fields":_vm.fields,"per-page":10},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.currentUser.features.create_save_to_my_template)?_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}}):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[(_vm.currentUser.features.create_save_to_my_template)?_c('edit-btn',{attrs:{"size":"xs"},on:{"click":function($event){return _vm.setEdit(rowData)}}}):_vm._e(),(_vm.currentUser.features.create_save_to_my_template)?_c('delete-btn',{attrs:{"size":"xs"},on:{"click":function($event){return _vm.setDelete(rowData)}}}):_vm._e(),(_vm.currentUser.features.create_save_to_my_template)?_c('edit-btn',{attrs:{"icon":"","color":"primary","text":"View"},on:{"click":function($event){return _vm.viewDetails(rowData)}}}):_vm._e(),(rowData.is_suspend && _vm.currentUser.role==='owner')?_c('btn',{attrs:{"color":"success","size":"xs","text":"Retrieve"},on:{"click":function($event){return _vm.suspendTemplate(rowData)}}}):_vm._e(),(!rowData.is_suspend && _vm.currentUser.role==='owner')?_c('btn',{attrs:{"color":"warning","size":"xs","text":"Suspend"},on:{"click":function($event){return _vm.suspendTemplate(rowData)}}}):_vm._e(),(!rowData.is_suspend)?_c('btn',{attrs:{"color":"primary","size":"xs","text":"Preview"},on:{"click":function($event){return _vm.preview(rowData)}}}):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c('modal',{ref:"addModal",attrs:{"no-close-on-backdrop":"","title":"Add Template","width":"30r","header-color":"primary"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{json: _vm.json}},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Template Name","disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('save-btn',{attrs:{"icon":"","text":"Save","color":"primary","size":"sm","disabled":loading}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Saving...")])],1):_vm._e()])]}}])})],1),_c('modal',{ref:"editModal",attrs:{"no-close-on-backdrop":"","title":"Edit Template","width":"30r","header-color":"primary"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Name","disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('save-btn',{attrs:{"icon":"","text":"Update","color":"primary","size":"sm","disabled":loading}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Updating...")])],1):_vm._e()])]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the Template "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". Are you sure ?")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }