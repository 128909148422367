<template>
    <div>
        <div v-if="loading" class="bg-primary p-3 mb-4" style="border-radius: 6px;">
            <loading-animation/>
            <span style=" vertical-align: super"> Loading details....</span>
        </div>
        <div v-if="!loading && permission" class="card container bs-5">
            <div v-if="details && permission">
                <div class="row">
                    <div class="col text-left mb-3">
                        <h4>{{ details.name }}</h4>
                    </div>
                    <div class="col text-right mb-3">
                        <btn v-if="!currentUser.is_superuser && currentUser.features.create_new_tender" type="button" color="success" size="sm"
                             @click="createTender(details)" text="Create Tender"/>
                        <btn type="button" class="ml-2" color="primary" size="sm"
                             @click="$router.push('/app/standard-templates/')" text="Back"/>
                    </div>
                </div>
                <Canvas :json="initial_json" :variable-json="variablesJson" @save="onSave"
                        :title="details.name + ' # ' + details.id"/>
            </div>

            <!--            Form Modals           -->

            <modal title="Create Tender" ref="addModal" width="30r" header-color="primary" no-close-on-backdrop>
                <b-form :save-url="addUrl" @success="formSuccess1" v-slot="{model, loading}"
                        :save-params="{ json, standard_template }">
                    <validated-input label="Tender Name"
                                     v-model="model.name"
                                     :rules="rules.name"
                                     :disabled="loading"/>
                    <validated-input label="Tender Number" name="Tender Number"
                                     v-model="model.tender_number"
                                     :rules="rules.tender_number"
                                     :disabled="loading"/>
                    <save-btn icon="" text="Create" color="primary" size="sm" :disabled="loading"/>
                </b-form>
            </modal>
        </div>
        <div v-if="!permission" class="card text-center bg-primary">Page not found!</div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';
import Canvas from '@/component/Bidder/BidView';
import OneBid from '@/data/Samples/OneBid';

export default {
    name       : 'StandardTemplateDetailsPage',
    components : { Canvas },
    data () {
        return {
            permission        : true,
            loading           : false,
            standard_template : '',
            addUrl            : urls.Consumer.Tender.create,
            id                : this.$route.params.id,
            json              : '',
            initial_json      : null,
            details           : [],
            editingItem       : {
                id   : this.$route.params.id,
                name : ''
            },
            rules : {
                name : {
                    required : true
                },
                tender_number : {
                    required : true
                }
            },
            variablesJson : JSON.stringify(OneBid)
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    beforeMount () {
        this.loadDetails();
    },
    methods : {
        onSave (json) {
            // Implement save here.
            this.editingItem.json = json;
            const that = this;
            axios.form(urls.Admin.StandardTemplate.addEdit, that.editingItem).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('My template saved', 'Success', {
                        type : 'success'
                    });
                    that.formSuccess();
                }
            });
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.Admin.StandardTemplate.details, { id : this.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json.template;
                    that.standard_template = json.template.id;
                    that.initial_json = JSON.stringify(json.template.json);
                    that.loading = false;
                    that.setEdit(that.details);
                } else if (json.permission === false) {
                    that.permission = json.permission;
                    that.loading = false;
                } else if (json.error === true) {
                    that.permission = json.permission;
                    that.loading = false;
                }
            });
        },
        createTender (details) {
            this.json = JSON.stringify(details.json);
            this.$refs.addModal.show();
        },
        formSuccess1 (response) {
            this.$refs.addModal.close();
        },
        setEdit (details) {
            this.editingItem.name = details.name;
        },
        formSuccess (response) {
            this.details = response.data.details;
        }
    }
}
;
</script>

<style scoped>
.text-small {
    font-weight: 500;
}

.form-control {
    border-radius: 5px;
}
</style>
