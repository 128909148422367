import '@fullcalendar/core/vdom';
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import lego from './plugins/lego';

import 'lego-framework/src/scss/variables/lego-variables.scss';
import './assets/style.scss';
import 'font-icons/nunito/nunito.css';
import 'font-icons/simple-line-icons/css/simple-line-icons.css';
import 'font-icons/iconsmind-s/css/iconsminds.css';
import 'font-icons/nucleo/css/nucleo.css';
import Globals from '@/utils/Globals';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);
Vue.use(PerfectScrollbar);
Vue.component('FullCalendar', FullCalendar);
Vue.use(dayGridPlugin);
Vue.use(listPlugin);
Vue.use(interactionPlugin);

Vue.config.productionTip = false;
Vue.prototype.$Globals = new Globals();
Vue.use(lego);

new Vue({
    router,
    store,
    render : h => h(App)
}).$mount('#app');
