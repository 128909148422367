const h1 = { value : 'Heading 1' };
const h2 = { value : 'Heading 2' };
const h3 = { value : 'Heading 3' };
const h4 = { value : 'Heading 4' };
const h5 = { value : 'Heading 5' };
const h6 = { value : 'Heading 6' };
const p = {
    value : 'Incididunt enim proident, velit in consequat. Irure dolore sed aute deserunt. Laboris nulla elit, eiusmod magna Lorem.\n' +
        'Irure tempor fugiat esse sed id. Cupidatat voluptate in Lorem. Anim eu in nostrud aute eiusmod.\n',
    alignment : 'text-justify'
};

export default {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p
};
