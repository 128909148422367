<template>
    <div>
        <div v-if="loading" class="bg-primary p-3 mb-4" style="border-radius: 6px;">
            <loading-animation/>
            <span style=" vertical-align: super"> Loading details....</span>
        </div>
        <div v-if="!loading && permission">
            <div class="card bs-5 mb-4" v-if="details">
                <div class="row mb-3">
                    <div class="col text-left">
                        <b style="font-size: 19px;">Company Details<span v-if="details.order"> - # {{ details.order }}</span></b>
                    </div>
                    <div class="col text-right">
                        <btn color="primary" size="sm" @click="$router.push('/app/company-list/')" text="Back"/>
                    </div>
                </div>
                <div class="m-3">
                    <img alt="logo" v-if="details.company_logo" :src="details.company_logo"/>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Company Name</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.company_name">{{
                                    details.company_name
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Registration Number</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.company_registration_no">{{
                                    details.company_registration_no
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Contact Number</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.contact_number">{{ details.contact_number }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <h6 class="font-weight-bold">Geographical Area of Operation</h6>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Country</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.country">{{ details.country }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>State</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.state">{{ details.state }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>District</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.district">{{ details.district }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Industry</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.industry">{{ details.industry }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Contact Person</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.contact_person">{{
                                    details.contact_person
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Category</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.category">{{
                                    details.category
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Service Provided</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.services_provided">{{ details.services_provided }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>E-Mail</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.contact_e_mail_id">{{ details.contact_e_mail_id }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Value of the Biggest Project</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.value_of_biggest_project">{{
                                    details.value_of_biggest_project
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Average annual turnover in last year</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.average_annual_turnover_in_last_year">{{
                                    details.average_annual_turnover_in_last_year
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Average annual turnover in last 2 year</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.average_annual_turnover_in_last_two_years">{{
                                    details.average_annual_turnover_in_last_two_years
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Average annual turnover in last 3 year</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.average_annual_turnover_in_last_three_years">{{
                                    details.average_annual_turnover_in_last_three_years
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col">
                            <span>Other details 1</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.other_details_1">{{
                                    details.other_details_1
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Other details 2</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.other_details_2">{{
                                    details.other_details_2
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <users v-if="details" :detail="this.$route.params.id"/>
            </div>
        </div>
        <div v-if="!permission" class="card text-center bg-primary">Page not found!</div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import Users from '../Users/Users';

export default {
    name       : 'CompanyDetailsPage',
    components : { Users },
    data () {
        return {
            loading    : false,
            permission : true,
            id         : this.$route.params.id,
            details    : []
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.Admin.Company.details, { company_id : that.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json['company-information'];
                    that.loading = false;
                } else if (json.permission === false) {
                    that.permission = json.permission;
                    that.loading = false;
                } else if (json.error === true) {
                    that.permission = json.permission;
                    that.loading = false;
                }
            });
        }
    }
};
</script>

<style scoped>
.text-small {
    font-weight: 500;
}

.form-control {
    border-radius: 5px;
}
</style>
