<template>
    <div>
        <div v-if="loading" class="bg-primary p-3 mb-4" style="border-radius: 6px;">
            <loading-animation/>
            <span style=" vertical-align: super"> Loading details....</span>
        </div>
        <div v-if="!loading" class="card">
            <div class="p-4">
                <div v-if="tender" class="row mb-3">
                    <div class="col"><b>Project Name</b> : {{ tender.name_of_project }}</div>
                </div>
                <div v-if="tender" class="row mb-3">
                    <div class="col"><b>Date of Report</b> : {{ tender.date_of_report }}</div>
                </div>
                <div class="row">
                    <div class="col" style="width: 50%">
                        <perfect-scrollbar>
                            <table id="table-1" class="table table-bordered" ref="table">
                                <thead>
                                <tr>
                                    <td colspan="2">
                                        <span v-if="tender"><b>Client Name</b> : {{ tender.name_of_client }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Item</td>
                                    <td class="font-weight-bold">Description</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(record) in  details1" v-bind:key="record.key">
                                    <td v-for="(i) in  record" v-bind:key="i.key" scope="row"><pre>{{ i }}</pre></td>
                                </tr>
                                </tbody>
                            </table>
                        </perfect-scrollbar>
                    </div>
                    <div class="col" style="width: 50%; margin: 0px !important;">
                        <perfect-scrollbar>
                            <table id="table-2" class="table table-bordered" ref="table">
                                <thead>
                                <tr v-if="detail">
                                    <td :colSpan="col_width" class="text-center font-weight-bold" style="width: fit-content"
                                        v-for="(record) in  detail[0].contractors" v-bind:key="record.id"><pre>{{ record.name }}</pre>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center font-weight-bold" v-for="(record) in  table_head"
                                        v-bind:key="record.key"
                                        scope="row"><pre>{{ record }}</pre>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(record) in  details" v-bind:key="record.key">
                                    <td class="text-center" v-for="(i) in  record" v-bind:key="i.key" scope="row"><pre>{{
                                            i
                                        }}</pre>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </perfect-scrollbar>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name  : 'BidderAnalysisComparisonPage',
    props : { tender_id : { type : String } },
    data () {
        return {
            id         : this.$route.params.id,
            permission : true,
            loading    : false,
            details    : [],
            details1   : [],
            detail     : null,
            table_head : [],
            col_width  : '',
            tender     : null
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        stripAnalysisDetails (table) {
            const initialData = this.detail;
            const data = [];
            const data1 = [];
            const tableHead = [];
            const tableBody = [];
            const subDetails = [];
            const arrayLength = initialData.length;
            for (let i = 0; i < arrayLength; i++) {
                var arrayLength1 = initialData[i].contractors.length;
                tableBody.push([initialData[i].index, initialData[i].description]);
                subDetails.push([]);
                for (let j = 0; j < arrayLength1; j++) {
                    if (table === 'boq-table' || table === 'boq-wo-qty-table') {
                        subDetails[i].push(
                            initialData[i].contractors[j].quantity,
                            initialData[i].contractors[j].unit,
                            initialData[i].contractors[j].rate,
                            initialData[i].contractors[j].total
                        );
                    } else if (table === 'sot-table') {
                        subDetails[i].push(
                            initialData[i].contractors[j].unit,
                            initialData[i].contractors[j].rate
                        );
                    } else if (table === 'msp-mnt-table') {
                        subDetails[i].push(
                            initialData[i].contractors[j].amount
                        );
                    }
                }
                data.push(subDetails);
                data1.push(tableBody);
            }
            for (let k = 0; k < arrayLength1; k++) {
                if (table === 'boq-table' || table === 'boq-wo-qty-table') {
                    tableHead.push('Quantity', 'Unit', 'Rate', 'Amount');
                    this.col_width = 4;
                } else if (table === 'sot-table') {
                    tableHead.push('Unit', 'Rate');
                    this.col_width = 2;
                } else if (table === 'msp-mnt-table') {
                    tableHead.push('Amount');
                    this.col_width = 1;
                }
            }
            this.details = data[data.length - 1];
            this.details1 = data1[data1.length - 1];
            this.table_head = tableHead;
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.Consumer.Tender.ComparisonAnalysisView, { tender_id : that.tender_id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.tender = json.tender;
                    if (json['boq-table']) {
                        that.detail = json['boq-table'];
                        const table = 'boq-table';
                        that.stripAnalysisDetails(table);
                    } else if (json['boq-wo-qty-table']) {
                        that.detail = json['boq-wo-qty-table'];
                        const table = 'boq-wo-qty-table';
                        that.stripAnalysisDetails(table);
                    } else if (json['sot-table']) {
                        that.detail = json['sot-table'];
                        const table = 'sot-table';
                        that.stripAnalysisDetails(table);
                    } else if (json['msp-mnt-table']) {
                        that.detail = json['msp-mnt-table'];
                        const table = 'msp-mnt-table';
                        that.stripAnalysisDetails(table);
                    }
                    that.loading = false;
                } else if (json.permission === false) {
                    that.permission = json.permission;
                    that.loading = false;
                } else if (json.error === true) {
                    that.permission = json.permission;
                    that.loading = false;
                }
            });
        }
    }
};
</script>

<style scoped>
.text-small {
    font-weight: 500;
}

.form-control {
    border-radius: 5px;
}

th {
    font-size: 13px !important;
}

pre {
    font-family: Montserrat, sans-serif;
}

</style>
