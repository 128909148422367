
const orderedList = {
    rows : [
        'Item 1'
    ]
};

export default {
    ol : orderedList,
    ul : orderedList
};
