<template>
    <div>
        <check-permission/>
        <vue-table-card :show-search-box="false" title="Message" class="card bs-5" :url="listUrl" :fields="fields"
                        :per-page="10" ref="table"/>
    </div>
</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'Messages',
    data () {
        return {
            listUrl : urls.Consumer.Message.list,
            fields  : [
                {
                    name      : 'message',
                    sortField : 'message',
                    title     : 'Message'
                },
                {
                    name      : 'created_on',
                    sortField : 'created_on',
                    title     : 'Date'
                },
                {
                    name       : '__slot:actions',
                    title      : '',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    }
};

</script>

<style scoped>

</style>
