<template>
    <div><validated-input type="text" /></div>
</template>

<script>
import BaseComponent from '@/component/Editor/BaseComponent';
export default {
    name    : 'editor-text-input',
    extends : BaseComponent

};
</script>
