<template>
    <div>
        <check-permission/>
        <div v-if="loading" class="bg-primary p-3 mb-4" style="border-radius: 6px;">
            <loading-animation/>
            <span style=" vertical-align: super"> Loading details....</span>
        </div>
        <div v-if="!loading">
            <div v-if="details" class="card bs-5">
                <div class="row">
                    <div class="col">
                        <b style="font-size: 18px;">PROFILE</b>
                    </div>
                    <div class="col text-right">
                        <btn color="primary" size="sm" @click="setEdit(details)" text="Edit Profile"/>
                    </div>
                </div>
                <modal no-close-on-backdrop title="Edit Profile" ref="editProfile" width="45r" header-color="primary">
                    <b-form :save-url="editUrl" @success="formSuccess" v-slot="{model, loading}"
                            :initial-data="editingItem">
                        <div class="row mb-2">
                            <div class="col-md-4">
                                <validated-input name="Name" :rules="rules.name"
                                                 label="Name" :disabled="loading"
                                                 v-model="model.name"></validated-input>
                            </div>
                            <div class="col-md-4">
                                <validated-vue-select name="Address Type" :options="addressTypeOptions"
                                                      label="Address Type" :disabled="loading"
                                                      v-model="model.address_type"></validated-vue-select>
                            </div>
                            <div class="col-md-4">
                                <checkbox-input class="mt-4" name="Is Primary Address" label="Is Primary Address"
                                                :disabled="loading"
                                                v-model="model.is_primary_address"></checkbox-input>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <label>Address</label>
                                <textarea-input name="Address" v-model="model.address"/>
                            </div>
                        </div>
                        <save-btn icon="" text="Update" color="primary" size="sm" :disabled="loading">
                            <span v-if="loading"><loading-animation/><span
                                style="vertical-align: super"> Updating...</span></span>
                        </save-btn>
                    </b-form>
                </modal>
                <div class="m-3">
                    <div class="row mb-3">
                        <div class="col">
                            <span>Name</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.name">{{
                                    details.name
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Username</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.username">{{ details.username }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Contact Number</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.staff_phone_number">{{ details.staff_phone_number }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>E-Mail</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.email">{{ details.email }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Role</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.role">{{
                                    details.role
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Company</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.company_name">{{ details.company_name }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Address</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.address">{{ details.address }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Address Type</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.address_type">{{
                                    details.address_type
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Is Primary Address</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.is_primary_address">
                                <div v-if="details.is_primary_address===true">Yes</div>
                            </span>
                            <span class="text-primary font-weight-bold" v-else>No</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card bs-5 mb-4" v-if="details">
                <div class="row mb-3">
                    <div class="col text-left">
                        <b style="font-size: 18px;">COMPANY DETAILS</b>
                    </div>
                    <div class="col text-right">
                        <btn v-if="currentUser.role==='owner'" color="primary" size="sm"
                             @click="setCompanyEdit(details)" text="Edit Company"/>
                    </div>
                </div>
                <div class="m-3">
                    <img alt="logo" v-if="details.company_logo" :src="details.company_logo"/>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Company Name</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.company_name">{{
                                    details.company_name
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Registration Number</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.company_registration_no">{{
                                    details.company_registration_no
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Contact Number</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.contact_number">{{ details.contact_number }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <h6 class="font-weight-bold">Geographical Area of Operation</h6>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Country</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.country_name">{{ details.country_name }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>State</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.state_name">{{ details.state_name }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>District</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.district_name">{{ details.district_name }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Industry</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.industry_name">{{ details.industry_name }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Contact Person</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.contact_person">{{
                                    details.contact_person
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Category</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.category_name">{{
                                    details.category_name
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Service Provided</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.services_provided_name">{{ details.services_provided_name }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>E-Mail</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.contact_e_mail_id">{{ details.contact_e_mail_id }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Value of the Biggest Project</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.value_of_biggest_project">{{ details.value_of_biggest_project }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <span>Average annual turnover in last year</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.average_annual_turnover_in_last_year">{{
                                    details.average_annual_turnover_in_last_year
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Average annual turnover in last 2 year</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.average_annual_turnover_in_last_two_years">{{
                                    details.average_annual_turnover_in_last_two_years
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Average annual turnover in last 3 year</span><br/>
                            <span class="text-primary font-weight-bold"
                                  v-if="details.average_annual_turnover_in_last_three_years">{{
                                    details.average_annual_turnover_in_last_three_years
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                    <div class="row pb-2">
                        <div class="col">
                            <span>Other details 1</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.other_details_1">{{
                                    details.other_details_1
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                        <div class="col">
                            <span>Other details 2</span><br/>
                            <span class="text-primary font-weight-bold" v-if="details.other_details_2">{{
                                    details.other_details_2
                                }}</span>
                            <span class="text-primary font-weight-bold" v-else>Nil</span>
                        </div>
                    </div>
                </div>
            </div>
            <modal no-close-on-backdrop title="Company" ref="editCompany" width="70r" header-color="primary">
                <b-form :save-url="editCompany" @success="formCompanySuccess" v-slot="{model, loading}"
                        :initial-data="editingItem" ref="editForm">
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-input name="Name" :rules="rules.company_name" label="Name"
                                             :disabled="loading"
                                             v-model="model.company_name"/>
                        </div>
                        <div class="col-md-4">
                            <validated-file-input name="Logo" label="Logo" :disabled="loading"
                                                  v-model="model.company_logo"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Registration Number" :rules="rules.company_registration_no"
                                             label="Registration Number"
                                             :disabled="loading"
                                             v-model="model.company_registration_no"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Contact Number" :rules="rules.contact_number" type="number"
                                             label="Contact Number" :disabled="loading"
                                             v-model="model.contact_number"/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-input name="E-Mail" :rules="rules.contact_e_mail_id" type="email"
                                             label="E-Mail"
                                             :disabled="loading"
                                             v-model="model.contact_e_mail_id"/>
                        </div>
                        <div class="col-md-4">
                            <validated-ajax-vue-select name="Vendor Category" multiple :url="vendorCategoryOptions"
                                                       :rules="rules.category" label="Vendor Category"
                                                       v-model="model.category"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Value of the Biggest Project" :rules="rules.value_of_biggest_project"
                                             label="Value of the Biggest Project"
                                             v-model="model.value_of_biggest_project"/>
                        </div>
                    </div>
                    <h6 class="font-weight-bold">Geographical Area of Operation</h6>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-ajax-vue-select name="Country" :url="countryOptions"
                                                       @input="loadStateOptions(model.country)"
                                                       :rules="rules.country" label="Country"
                                                       v-model="model.country"/>
                        </div>
                        <div class="col-md-4">
                            <validated-vue-select name="State" :options="stateOptions"
                                                  @input="loadDistrictOptions(model.state)"
                                                  :rules="rules.state"
                                                  label="State"
                                                  v-model="model.state"/>
                        </div>
                        <div class="col-md-4">
                            <validated-vue-select name="District" :options="districtOptions"
                                                  @input="checkIfNullValue(model.district)"
                                                  :rules="rules.district" label="District"
                                                  v-model="model.district" multiple/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-ajax-vue-select name="Industry" :url="industryOptions"
                                                       :rules="rules.industry" label="Industry"
                                                       v-model="model.industry"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Contact Person" :rules="rules.contact_person" label="Contact Person"
                                             :disabled="loading"
                                             v-model="model.contact_person"/>
                        </div>
                        <div class="col-md-4">
                            <validated-ajax-vue-select name="Services Provided" :url="servicesOptions" multiple
                                                       :rules="rules.services_provided" label="Services Provided"
                                                       v-model="model.services_provided"/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <validated-input name="Average Annual Turnover In Last Year"
                                             :rules="rules.average_annual_turnover_in_last_year"
                                             label="Average Annual Turnover In Last Year"
                                             :disabled="loading" type="number"
                                             v-model="model.average_annual_turnover_in_last_year"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Average Annual Turnover In Last 2 Years"
                                             :rules="rules.average_annual_turnover_in_last_two_years"
                                             label="Average Annual Turnover In Last 2 Years"
                                             :disabled="loading" type="number"
                                             v-model="model.average_annual_turnover_in_last_two_years"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Average Annual Turnover In Last 3 Years"
                                             :rules="rules.average_annual_turnover_in_last_three_years"
                                             label="Average Annual Turnover In Last 3 Years"
                                             :disabled="loading" type="number"
                                             v-model="model.average_annual_turnover_in_last_three_years"/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label>Other Details 1</label>
                            <textarea-input name="Other Details 1" v-model="model.other_details_1"/>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label>Other Details 2</label>
                            <textarea-input name="Other Details 2" v-model="model.other_details_2"/>
                        </div>
                    </div>
                    <save-btn text="Update" icon="" color="primary" size="sm" :disabled="loading">
                        <span v-if="loading"><loading-animation/><span style="vertical-align: super"> Updating...</span></span>
                    </save-btn>
                </b-form>
            </modal>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name : 'MyProfile',
    data () {
        return {
            editUrl               : urls.Consumer.Profile.addEdit,
            editCompany           : urls.Consumer.Company.addEdit,
            industryOptions       : urls.Admin.Company.industryOptions,
            vendorCategoryOptions : urls.Admin.Company.vendorCategoryOptions,
            servicesOptions       : urls.Admin.Company.servicesOptions,
            stateOptions          : [],
            countryOptions        : urls.Consumer.Company.countryOptions,
            districtOptions       : [],
            loading               : false,
            editProfileUrl        : null,
            editingItem           : null,
            details               : [],
            addressTypeOptions    : [
                { label : 'Office', value : 'Office' },
                { label : 'Home', value : 'Home' },
                { label : 'Other', value : 'Other' }
            ],
            rules : {
                name : {
                    required : true
                },
                contact_person : {
                    required : false
                },
                company_name : {
                    required : true
                },
                value_of_biggest_project : {
                    required : false
                },
                company_registration_no : {
                    required : false
                },
                contact_number : {
                    required : false
                },
                contact_e_mail_id : {
                    required : false
                },
                industry : {
                    required : false
                },
                geographical_area_of_operations : {
                    required : false
                },
                services_provided : {
                    required : false
                },
                average_annual_turnover_in_last_year : {
                    required : false
                },
                average_annual_turnover_in_last_two_years : {
                    required : false
                },
                average_annual_turnover_in_last_three_years : {
                    required : false
                },
                category : {
                    required : false
                }
            }
        };
    },
    computed : {
        ...mapGetters(['currentUser'])
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        loadStateOptions (item) {
            if (this.$refs.editForm.getModelValue('country') === null) {
                this.$refs.editForm.setModelValue('country', '');
            }
            this.$refs.editForm.setModelValue('state', '');
            this.$refs.editForm.setModelValue('district', '');
            this.stateOptions = [];
            this.districtOptions = [];
            const that = this;
            axios.form(urls.Admin.Company.stateOptions, { country : item }).then(function (response) {
                const json = response.data;
                that.stateOptions = json.data;
                that.districtOptions = [];
            });
        },
        loadDistrictOptions (item) {
            this.districtOptions = [];
            if (this.$refs.editForm.getModelValue('state') === null) {
                this.$refs.editForm.setModelValue('state', '');
            }
            this.$refs.editForm.setModelValue('district', '');
            const that = this;
            axios.form(urls.Admin.Company.districtOption, { state : item }).then(function (response) {
                const json = response.data;
                that.districtOptions = json.data;
            });
        },
        checkIfNullValue (item) {
            if (this.$refs.editForm.getModelValue('district') === null) {
                this.$refs.editForm.setModelValue('district', '');
            }
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.get(urls.Consumer.Profile.details).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json.data;
                    if (that.details.country) {
                        axios.form(urls.Admin.Company.stateOptions, { country : that.details.country }).then(function (response) {
                            const json = response.data;
                            that.stateOptions = json.data;
                        });
                    }
                    if (that.details.state) {
                        axios.form(urls.Admin.Company.districtOption, { state : that.details.state }).then(function (response) {
                            const json = response.data;
                            that.districtOptions = json.data;
                        });
                    }
                    that.loading = false;
                }
            });
        },
        formSuccess (response) {
            const refs = this.$refs;
            this.details.name = response.data.name;
            this.details.address = response.data.address;
            this.details.is_primary_address = response.data.is_primary_address;
            this.details.address_type = response.data.address_type;
            refs.editProfile.close();
            refs.editCompany.close();

            this.editingItem = null;
        },
        formCompanySuccess (response) {
            const refs = this.$refs;
            this.details = response.data.data.data;
            refs.editCompany.close();

            this.editingItem = null;
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editProfile.show();
        },
        setCompanyEdit (item) {
            this.editingItem = { ...item };
            this.editingItem.id = item.company_id;
            this.editingItem.services_provided = item.service_provided;
            this.$refs.editCompany.show();
        }
    }
};
</script>

<style scoped>
.form-control {
    border-radius: 5px;
}
</style>
