<template>
    <div><validated-input type="password" /></div>
</template>

<script>
import BaseComponent from '@/component/Editor/BaseComponent';
export default {
    name    : 'editor-password-input',
    extends : BaseComponent

};
</script>
